<template>
  <div class="leftItem" style="color: #fff;">
    <populationBaseInfo />
    <healthManagement />
    <activity />
    <activityChart />
  </div>
</template>

<script>
export default {
  components: {
    populationBaseInfo: () => import('./component/populationBaseInfo.vue'),
    healthManagement: () => import('./component/healthManagement.vue'),
    activity: () => import('./component/activity.vue'),
    activityChart: () => import('./component/activityChart.vue'),
  },
}
</script>

<style scoped></style>
