<template>
  <!-- 社区内社区养老服务信息 -->
  <div class="service-information">
    <div class="service">
      <div class="fraction">
        <div class="item spcol">
          <div>
            <span>{{ aggregateMessage.total }}</span>
          </div>
          <p>会员人数</p>
        </div>
        <div class="item">
          <div>
            <span>{{ aggregateMessage.pointTotal }}</span>
          </div>
          <p>积分养老累计总分</p>
        </div>
        <div class="item">
          <div>
            <span>{{ aggregateMessage.pointCashTotal }}</span>
          </div>
          <p>积分养老累计兑换总分</p>
        </div>
      </div>
    </div>
    <div class="topTen">
      <div class="box">
        <div class="echartsTu">
          <div>
            <div id="distribution" style="width: 100%; height: 100%"></div>
          </div>
          <div>
            <div id="distributiontwo" style="width: 100%; height: 100%"></div>
          </div>
        </div>
      </div>
      <div class="yuandehen">
        <div class="title">床位信息</div>
        <ul class="fore">
          <li>
            <div>{{ bedMessage.total }}</div>
            <div>总床位数</div>
          </li>
          <li>
            <div>{{ bedMessage.nurseTotal }}</div>
            <div>护理床位数</div>
          </li>
          <li>
            <div>{{ bedMessage.nursePercent }}</div>
            <div>护理床占比</div>
          </li>
          <li>
            <div>{{ bedMessage.incomeTotal }}</div>
            <div>已入驻人数</div>
          </li>
          <li>
            <div>{{ bedMessage.incomePercent }}</div>
            <div>入住率</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
let echarts = require('echarts')
import { getAggregate, getBed } from '@/api/institution/right.js'
import 'echarts-liquidfill'
import {
  rmyy,
  heln,
  ny,
  zj,
  bz,
  ds,
  sq,
  dszx,
  st,
  gc,
  js,
  yj,
} from './topOneMock'
export default {
  data() {
    return {
      aggregateMessage: {
        total: 0, //社区敬老卡会员人数
        pointTotal: 0, //积分养老统计总分
        pointCashTotal: 0, //积分养老累计兑换总分
        services: [
          {
            key: '洗脚服务',
            value: 9,
          },
          {
            key: '泡澡五福',
            value: 8,
          },
        ],
      },
      flag: false,
      bedMessage: {
        total: 0, // 总床位数
        nurseTotal: 0, //护理床位数
        nursePercent: '0%', //护理床位占比
        incomeTotal: 0, //已入驻人数
        incomePercent: '0%', //已入驻率
      },
    }
  },
  mounted() {
    this.topTen()
  },
  computed: {
    insNo() {
      return this.$route.query.insNo
    },
  },
  methods: {
    distribution(name, item) {
      this.$nextTick(() => {
        // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById('distribution'))
        myChart.setOption({
          grid: {
            left: '-25%',
            right: '5%',
            bottom: '1%',
            top: '5%',
            containLabel: true,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none',
            },
            formatter: function (params) {
              return (
                params[0].name +
                '<br/>' +
                "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:#FF9533'></span>" +
                params[0].seriesName +
                ' : ' +
                Number(
                  (params[0].value.toFixed(4) / 10000).toFixed(2)
                ).toLocaleString() +
                ' 次<br/>'
              )
            },
          },
          xAxis: {
            show: false,
            type: 'value',
          },
          yAxis: [
            {
              type: 'category',
              inverse: true,
              axisLabel: {
                show: true,
                margin: 80,
                textStyle: {
                  color: '#fff',
                  align: 'left',
                },
                formatter: (params, index) => {
                  return [`{a${index + 1}|  ${index + 1}}  ${params}`].join(
                    '\n'
                  )
                },
                rich: {
                  a1: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a2: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a3: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a4: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a5: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                },
              },
              splitLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              data: name,
            },
            {
              type: 'category',
              inverse: true,
              axisTick: 'none',
              axisLine: 'none',
              show: true,
              axisLabel: {
                textStyle: {
                  color: '#8EB8E6',
                  fontSize: '12',
                },
                formatter: function (value) {
                  if (value >= 10000) {
                    return (value / 10000).toLocaleString() + '万'
                  } else {
                    return value.toLocaleString()
                  }
                },
              },
              data: item,
            },
          ],
          series: [
            {
              name: '次数',
              type: 'bar',
              zlevel: 1,
              itemStyle: {
                normal: {
                  barBorderRadius: 30,
                  color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: '#FFCF43',
                    },
                    {
                      offset: 1,
                      color: '#FF9533',
                    },
                  ]),
                },
              },
              barWidth: 9,
              data: item,
            },
            {
              name: '背景',
              type: 'bar',
              barWidth: 9,
              barGap: '-100%',
              data: item,
              itemStyle: {
                normal: {
                  color: 'rgba(24,31,68,1)',
                  barBorderRadius: 30,
                },
              },
            },
          ],
        })
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      })
    },
    distributiontwo(name, item, max) {
      this.$nextTick(() => {
        // 基于准备好的dom，初始化echarts实例
        let myCharts = echarts.init(document.getElementById('distributiontwo'))
        myCharts.setOption({
          grid: {
            left: '-25%',
            right: '5%',
            bottom: '1%',
            top: '5%',
            containLabel: true,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none',
            },
            formatter: function (params) {
              return (
                params[0].name +
                '<br/>' +
                "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:#FF9533'></span>" +
                params[0].seriesName +
                ' : ' +
                Number(
                  (params[0].value.toFixed(4) / 10000).toFixed(2)
                ).toLocaleString() +
                ' 万元<br/>'
              )
            },
          },
          xAxis: {
            show: false,
            type: 'value',
            max: max,
          },
          yAxis: [
            {
              type: 'category',
              inverse: true,
              axisLabel: {
                show: true,
                margin: 80,
                textStyle: {
                  color: '#fff',
                  align: 'left',
                },
                formatter: (params, index) => {
                  return [
                    `{a${index + 1 + 5}|  ${index + 1 + 5}}  ${params}`,
                  ].join('\n')
                },
                rich: {
                  a6: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a7: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a8: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a9: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a10: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 7, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                },
              },

              splitLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              markPoint: 1,
              data: name,
            },
            {
              type: 'category',
              inverse: true,
              axisTick: 'none',
              axisLine: 'none',
              show: true,
              axisLabel: {
                textStyle: {
                  color: '#8EB8E6',
                  fontSize: '12',
                },
                formatter: function (value) {
                  if (value >= 10000) {
                    return (value / 10000).toLocaleString() + '万'
                  } else {
                    return value.toLocaleString()
                  }
                },
              },
              data: item,
            },
          ],
          series: [
            {
              name: '金额',
              type: 'bar',
              zlevel: 1,
              itemStyle: {
                normal: {
                  barBorderRadius: 30,
                  color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: '#FFCF43',
                    },
                    {
                      offset: 1,
                      color: '#FF9533',
                    },
                  ]),
                },
              },
              barWidth: 9,
              data: item,
            },
            {
              name: '背景',
              type: 'bar',
              barWidth: 9,
              barGap: '-100%',
              data: item,
              itemStyle: {
                normal: {
                  color: '#8EB8E6',
                  barBorderRadius: 30,
                },
              },
            },
          ],
        })
        window.addEventListener('resize', function () {
          myCharts.resize()
        })
      })
    },
    // 获取到社区服务前十名
    topTen() {
      getAggregate(this.insNo)
        .then((res) => {
          if (res.code === 0) {
            switch (this.insNo) {
              case 'SP0020HP0013':
                // 人民医院
                this.aggregateMessage = rmyy
                break
              case 'SP0020HP0009':
                // 达州市达川区华尔老年公寓
                this.aggregateMessage = heln
                break
              case 'SP0020HP0015':
                // 南岳
                this.aggregateMessage = ny
                break
              case 'SP0020HP0017':
                // 赵家
                this.aggregateMessage = zj
                break
              case 'SP0020HP0012':
                // 堡子中心敬老院
                this.aggregateMessage = bz
                break
              case 'SP0020HP0014':
                // 大树中心敬老院
                this.aggregateMessage = ds
                break
              case 'SP0020HP0018':
                // 达州市达川区石桥中心敬老院
                this.aggregateMessage = sq
                break
              case 'SP0020HP0010':
                // 达州市达川区渡市中心敬老院
                this.aggregateMessage = dszx
                break
              case 'SP0020HP0008':
                // 达州市达川区石梯中心敬老院
                this.aggregateMessage = st
                break
              case 'SP0020HP0007':
                // 达州市达川区管村中心敬老院
                this.aggregateMessage = gc
                break
              case 'SP0020HP0016':
                // 达州市达川区景市镇中心敬老院
                this.aggregateMessage = js
                break
              case 'SP0020HP0004':
                // 达川区医教养示范园（四川欢哥养老服务有限公司）
                this.aggregateMessage = yj
                break
              default:
                this.aggregateMessage = res.data
                break
            }
          } else {
            return
          }

          const copy = JSON.parse(
            JSON.stringify(this.aggregateMessage.services)
          )
          if (copy.length <= 10) {
            const count = 10 - this.aggregateMessage.services.length
            for (let i = 0; i < count; i++) {
              copy.push({ key: '暂无数据', value: 0 })
            }
          }
          const topFiveList = copy.splice(0, 5)
          const topFive = topFiveList.map((item) => {
            const copy = item
            return copy.key
          })
          console.log(11, topFive)
          const topFiveData = topFiveList.map((item) => {
            const copy = item
            return copy.value
          })
          const lastFiveList = copy
          const lastFive = lastFiveList.map((item) => {
            const copy = item
            return copy.key
          })
          const lastFiveData = lastFiveList.map((item) => {
            const copy = item
            return copy.value
          })
          const max = topFiveData[0]
          // 赋值渲染
          this.flag = true
          this.distribution(topFive, topFiveData)
          this.distributiontwo(lastFive, lastFiveData, max)
        })
        .catch((error) => {
          console.log(error)
        })
      getBed(this.insNo).then((res) => {
        if (res.code === 0) {
          this.bedMessage = res.data
        }
      })
    },
  },
}
</script>

<style scoped>
.textTitle {
  height: 1.6rem;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
  color: #fff;
}
/* 社区内社区养老服务信息 */
.service {
  background: url('~@/assets/images/institution_images/kuan.png') 0 0 no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.3rem;
}
.peopleAll {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 3.5rem;
}
.peopleAll > div {
  display: flex;
  background: url('~@/assets/images/institution_images/chang.png') 0 0 no-repeat;
  background-size: 100% 100%;
  height: 2.5rem;
  width: 85%;
  align-items: center;
  justify-content: center;
}
.peopleAll > div > span {
  font-size: 0.6rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #00d5ff;
  margin-right: 0.5rem;
}
.peopleAll > div > ul {
  display: flex;
}
.peopleAll > div > ul > li {
  padding: 0.1rem 0.2rem;
  font-size: 1rem;
  background: #0079fe;
  border-radius: 4px;
  margin-right: 0.1rem;
}
.peopleAll > div > div {
  margin-left: 0.1rem;
}
.fraction {
  display: flex;
  justify-content: space-around;
  background: url('~@/assets/images/institution_images/topbg.png') 0 0 no-repeat;
  padding: 1.5rem 0.25rem 0.5rem 0.25rem;
}
.fraction .item {
  text-align: center;
}
.fraction .item p {
  margin-top: 0.3rem;
}
.spcol {
  color: #ff8529 !important;
}
.fraction > div {
  width: 6.5rem;
  color: #fff;
  padding: 0.5rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  margin-top: 2px;
}
.fraction > div > div {
  text-align: center;
}
.fraction > div > div > span:first-child {
  font-size: 1rem;
  font-family: DINAlternate-Bold, DINAlternate;
}
.topTen {
  background: url('~@/assets/images/institution_images/top2bg.png') 0 0
    no-repeat;
  padding: 1.06rem 0 0.5rem 0;
  margin-bottom: 0.3rem;
  height: 12rem;
  background-size: 100% 100%;
  box-sizing: border-box;
}
.topTen > div:first-child {
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffd725;
  text-indent: 1rem;
  line-height: 1rem;
}
.echartsTu {
  height: 7rem;
  margin: 0 1rem;
  display: flex;
}
.box {
  height: 6rem;
  overflow: hidden;
}
.echartsTu > div {
  flex: 1;
}
.yuandehen {
  margin-top: 0.45rem;
}
.yuandehen .title {
  color: #69b1ff;
  font-size: 0.58rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-weight: bold;
}

.shu {
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #fece0e;
  font-size: 1rem;
}
.chuna {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fefefe;
}
.fore {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;
}
.fore > li {
  text-align: center;
  width: 4.3rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  padding: 0.3rem 0;
  border: 1px solid #213060;
}
.fore > li:first-child div {
  text-align: center;
  color: #ff8529 !important;
}
.fore > li > div:first-child {
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  font-size: 1rem;
  color: #8eb8e6;
}
.fore > li > div:nth-child(2) {
  font-family: Source Han Sans CN;
  color: #8eb8e6;
  margin-top: 0.25rem;
  font-size: 0.58rem;
}
</style>
