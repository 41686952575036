<template>
  <div class="rightItem" style="width: 24rem">
    <!-- 社区内社区养老服务信息 -->
    <topRightcomplexOne></topRightcomplexOne>
    <!-- 养老服务实时数据 -->
    <topRightcomplexTwo></topRightcomplexTwo>
    <!-- 监控 -->
    <bottomRight></bottomRight>
  </div>
</template>
<script>
import topRightcomplexOne from './components/topRightcomplexOne.vue'
import topRightcomplexTwo from './components/topRightcomplexTwo.vue'
import bottomRight from './components/bottomRight.vue'

export default {
  //注册组件
  components: {
    topRightcomplexOne,
    topRightcomplexTwo,
    bottomRight,
  },
}
</script>
<style></style>
