/**
 * 
赵家，SP0020HP0017
石梯，SP0020HP0008
石桥，SP0020HP0018
南岳，SP0020HP0015
人民医院，SP0020HP0013
景市，SP0020HP0016
华尔，SP0020HP0009
管村，SP0020HP0007
渡市，SP0020HP0010
大树，SP0020HP0014
堡子，SP0020HP0012
示范园，SP0020HP0004
 */

//达川区人民医院康养中心
export const rmyy = {
  total: 12398,
  pointTotal: 0,
  pointCashTotal: 0,
  recordTotal: 0,
  services: [
    {
      key: '助餐',
      value: 89,
    },
    {
      key: '理发',
      value: 76,
    },
    {
      key: '体检',
      value: 51,
    },
    {
      key: '棋牌',
      value: 42,
    },
  ],
}
//达州市达川区华尔老年公寓
export const heln = {
  total: 9587,
  pointTotal: 0,
  pointCashTotal: 0,
  recordTotal: 0,
  services: [
    {
      key: '助餐',
      value: 76,
    },
    {
      key: '理发',
      value: 71,
    },
    {
      key: '体检',
      value: 65,
    },
    {
      key: '棋牌',
      value: 48,
    },
  ],
}
// 南岳中心敬老院
export  const ny = {
  total: 2689,
  pointTotal: 0,
  pointCashTotal: 0,
  recordTotal: 0,
  services: [
    {
      key: '理发',
      value: 83,
    },
    {
      key: '助餐',
      value: 65,
    },
    {
      key: '体检',
      value: 56,
    },
    {
      key: '棋牌',
      value: 45,
    },
  ],
}

// 达州市达川区赵家镇敬老院
export const zj = {
  total: 3126,
  pointTotal: 0,
  pointCashTotal: 0,
  recordTotal: 0,
  services: [
    {
      key: '助餐',
      value: 90,
    },
    {
      key: '理发',
      value: 84,
    },
    {
      key: '体检',
      value: 57,
    },
    {
      key: '棋牌',
      value: 48,
    },
  ],
}
// 堡子中心敬老院
export const bz = {
  total: 2653,
  pointTotal: 0,
  pointCashTotal: 0,
  recordTotal: 0,
  services: [
    {
      key: '助餐',
      value: 77,
    },
    {
      key: '体检',
      value: 56,
    },
    {
      key: '理发',
      value: 46,
    },
    {
      key: '棋牌',
      value: 32,
    },
  ],
}
// 大树中心敬老院
export const ds = {
  total: 4236,
  pointTotal: 0,
  pointCashTotal: 0,
  recordTotal: 0,
  services: [
    {
      key: '助餐',
      value: 97,
    },
    {
      key: '理发',
      value: 88,
    },
    {
      key: '体检',
      value: 71,
    },
    {
      key: '棋牌',
      value: 43,
    },
  ],
}
// 达州市达川区石桥中心敬老院
export const sq = {
  total: 10239,
  pointTotal: 0,
  pointCashTotal: 0,
  recordTotal: 0,
  services: [
    {
      key: '助餐',
      value: 98,
    },
    {
      key: '理发',
      value: 92,
    },
    {
      key: '体检',
      value: 84,
    },
    {
      key: '棋牌',
      value: 76,
    },
  ],
}

// 达州市达川区渡市中心敬老院
export const dszx = {
  total: 4622,
  pointTotal: 0,
  pointCashTotal: 0,
  recordTotal: 0,
  services: [
    {
      key: '助餐',
      value: 62,
    },
    {
      key: '棋牌',
      value: 54,
    },
    {
      key: '理发',
      value: 32,
    },
    {
      key: '体检',
      value: 24,
    },
  ],
}

// 达州市达川区石梯中心敬老院
export const st = {
  total: 4322,
  pointTotal: 0,
  pointCashTotal: 0,
  recordTotal: 0,
  services: [
    {
      key: '理发',
      value: 76,
    },
    {
      key: '助餐',
      value: 62,
    },
    {
      key: '棋牌',
      value: 54,
    },
    {
      key: '体检',
      value: 21,
    },
  ],
}

// 达州市达川区管村中心敬老院
export const gc = {
  total: 5683,
  pointTotal: 0,
  pointCashTotal: 0,
  recordTotal: 0,
  services: [
    {
      key: '助餐',
      value: 87,
    },
    {
      key: '理发',
      value: 71,
    },
    {
      key: '棋牌',
      value: 43,
    },
    {
      key: '体检',
      value: 40,
    },
  ],
}

// 达州市达川区景市镇中心敬老院
export const js = {
  total: 3895,
  pointTotal: 0,
  pointCashTotal: 0,
  recordTotal: 0,
  services: [
    {
      key: '理发',
      value: 62,
    },
    {
      key: '助餐',
      value: 50,
    },
    {
      key: '棋牌',
      value: 32,
    },
    {
      key: '体检',
      value: 26,
    },
  ],
}

//达川区医教养示范园（四川欢哥养老服务有限公司）
export const yj = {
  total: 4123,
  pointTotal: 0,
  pointCashTotal: 0,
  recordTotal: 0,
  services: [
    {
      key: '理发',
      value: 78,
    },
    {
      key: '助餐',
      value: 65,
    },
    {
      key: '棋牌',
      value: 34,
    },
    {
      key: '体检',
      value: 29,
    },
  ],
}