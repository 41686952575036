<template>
  <div class="middleItem">
    <div>
      <areaMap></areaMap>
    </div>
    <div>
      <project></project>
    </div>
  </div>
</template>
<script>
import areaMap from './components/areaMap.vue'
import project from './components/project.vue'
export default {
  components: {
    areaMap,
    project,
  },
}
</script>
<style scoped></style>
