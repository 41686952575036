import request from '@/utils/request'

//获取食品留样记录
export const getFood = (insNo) =>
  request({
    url: `/hp/platform/data/v1/screen/ins/food/sample/list?institutionNo=${insNo}`,
    method: 'get',
  })

//获取安全信息
export const getSecurityInformation = (insNo) =>
  request({
    url: '/hp/platform/data/v1/screen/ins/securityInformation?insNo=' + insNo,
    method: 'get',
  })

//获取机构信息
export const getInstitutionInfo = (insNo) =>
  request({
    url: '/hp/platform/data/v1/screen/ins/institutionInfo?insNo=' + insNo,
    method: 'get',
  })
