import request from '@/utils/request'

//获取机构内社区养老服务信息
export const getAggregate = (insNo) =>
  request({
    url: '/hp/platform/data/v1/screen/ins/aggregate?insNo=' + insNo,
    method: 'get',
  })

//获取总床位护理床位
export const getBed = (insNo) =>
  request({
    url: '/hp/platform/data/v1/screen/ins/bed?insNo=' + insNo,
    method: 'get',
  })

//居家服务实时数据
export const getRelatime = (insNo) =>
  request({
    url: '/hp/platform/data/v1/screen/ins/realtime_data?insNo=' + insNo,
    method: 'get',
  })

//   获取设备地址列表
export const getMonitoring = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/live/list',
    method: 'get',
    params: data,
  })

//获取token
export const getToken = (institutionNo) =>
  request({
    url: `/hp/platform/data/v1/screen/gov/ysy/accessToken/${institutionNo}`,
    method: 'get',
  })
