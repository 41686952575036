<template>
  <div class="sample-safety">
    <div class="sample">
      <div v-if="listData && listData.length !== 0" class="sample-title">
        <div>
          <div>抽检项目</div>
          <div style="width: 60%">留样时间</div>
          <div>规格</div>
          <div>数量</div>
          <div>留样人</div>
        </div>
        <div>
          <vue-seamless-scroll :data="listData" class="seamless-warp">
            <ul class="item">
              <li
                v-for="(item, index) in listData"
                :key="index"
                class="item-li"
              >
                <span class="date" v-text="item.inspectItem"></span>
                <span
                  class="date"
                  style="width: 60%"
                  v-text="item.sampleKeepTime"
                ></span>
                <span class="date" v-text="item.grade"></span>
                <span class="date" v-text="item.num"></span>
                <span class="date" v-text="item.sampleKeeper"></span>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
      <div v-else class="sample-titles">
        <div>暂无数据</div>
      </div>
    </div>
    <div class="safety">
      <div class="safety-title">
        <div v-if="secureData && secureData.length !== 0" class="safety-main">
          <vue-seamless-scroll :data="secureData" class="seamless-warps">
            <ul class="item">
              <li v-for="(items, index) in secureData" :key="index">
                <div class="safety-item">
                  <div>{{ items.name }}</div>
                  <div v-if="items.status == '正常'" class="normal">
                    <div class="circular"></div>
                    <div>正常</div>
                  </div>
                  <div v-else class="abnormal">
                    <div class="circular"></div>
                    <div>异常</div>
                  </div>
                  <div>
                    <span>巡检人：</span>
                    <span>{{ items.inspector }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
        <div v-else class="sample-titles">
          <div>暂无数据</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFood, getSecurityInformation } from '@/api/institution/middle.js'
export default {
  data() {
    return {
      listData: [],
      secureData: [],
    }
  },
  mounted() {
    this.getFood()
    this.getSecurityInformation()
  },
  methods: {
    getFood() {
      getFood(this.$route.query.insNo).then((res) => {
        if (res.code == 0) {
          this.listData = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },

    // 2024-5-31 根据每个地方 显示不同的安全员
    formatSecurityData(data, name) {
      data.forEach((item) => {
        item.inspector = name
      })
    },
    getSecurityInformation() {
      getSecurityInformation(this.$route.query.insNo).then((res) => {
        if (res.code == 0) {
          if (!res.data.length) {
            return
          }

          switch (this.$route.query.insNo) {
            case 'SP0020HP0017':
              this.formatSecurityData(res.data, '聂大春')
              break
            case 'SP0020HP0008':
              this.formatSecurityData(res.data, '何小斌')
              break
            case 'SP0020HP0018':
              this.formatSecurityData(res.data, '王绍勇')
              break
            case 'SP0020HP0015':
              this.formatSecurityData(res.data, '宋春')
              break
            case 'SP0020HP0013':
              this.formatSecurityData(res.data, '乔俊东')
              break
            case 'SP0020HP0016':
              this.formatSecurityData(res.data, '秦春霞')
              break
            case 'SP0020HP0009':
              this.formatSecurityData(res.data, '唐宗仁')
              break
            case 'SP0020HP0007':
              this.formatSecurityData(res.data, '付一桂')
              break
            case 'SP0020HP0010':
              this.formatSecurityData(res.data, '王昌华')
              break
            case 'SP0020HP0014':
              this.formatSecurityData(res.data, '范书毅')
              break
            case 'SP0020HP0012':
              this.formatSecurityData(res.data, '杜善浩')
              break
            default:
              break
          }
          this.secureData = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
}
</script>
<style scoped>
.sample-safety {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.45rem;
}
.sample {
  height: 9rem;
  background: url('~@/assets/images/institution_images/zhucanliuyang.png') 0 0
    no-repeat;
  background-size: 100% 100%;
  color: aqua;
  flex: 1.5;
  margin-right: 0.3rem;
  padding: 0.4rem;
  padding-top: 1.5rem;
}
.safety {
  background: url('~@/assets/images/institution_images/anquanxinxi.png') 0 0
    no-repeat;
  background-size: 100% 100%;
  flex: 1;
  padding: 0.3rem;
}
.sample-title {
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  padding: 0.2rem;
}
.sample-title > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  height: 1rem;
}
.sample-title > div:first-child > div {
  width: 25%;
}

.seamless-warp {
  height: 7.5rem;
  overflow: hidden;
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 1rem;
}

.seamless-warps {
  height: 100%;
  overflow: hidden;
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 1rem;
}
.item-li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-li > span {
  display: inline-block;
  width: 25%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 0.2rem;
}

.safety-title {
  padding-top: 1.3rem;
}
.safety-main {
  height: 8.9rem;
}
.safety-item {
  display: flex;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.3rem;
  color: #8eb8e6;
  margin-top: 0.3rem;
}
.normal {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #32cf57;
}
.normal .circular {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: #32cf57;
  margin-right: 0.3rem;
}
.abnormal {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e72929;
}
.abnormal .circular {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: #e72929;
  margin-right: 0.3rem;
}
.sample-titles {
  width: 100%;
  height: 8.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2d3769;
  font-size: 0.9rem;
}
</style>
