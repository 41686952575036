<template>
  <!-- 颐孝通实时服务数据 -->
  <div class="yi-xiaotong">
    <div class="xiaotong">
      <div class="peopleAll">
        <div>
          <span class="spcol">累计服务人次</span>
          <span class="spcol">{{ realtime.total }}</span>
        </div>
      </div>
      <div class="peopleAll">
        <div>
          <span>累计服务金额 </span>
          <i>￥</i>
          <span>{{ realtime.amount }}</span>
        </div>
      </div>
    </div>
    <div class="order-display">
      <div class="order-cs">
        <div>今日在线订单显示</div>
        <div>
          <span>今日在线服务客户：</span
          ><span>{{ realtime.currCustomer }}人</span>
        </div>
        <div>
          <span>今日在线服务人员：</span
          ><span>{{ realtime.currWorker || 0 }}人</span>
        </div>
      </div>
      <div class="display-cs">
        <ul class="rolling-item-cs rolling-item-bor">
          <li>
            <span class="title">序号</span>
            <span class="address">时间</span>
            <span class="settled">订单编号</span>
            <span class="settledNum">服务项目</span>
            <span class="settledNum">状态</span>
          </li>
        </ul>
        <vue-seamless-scroll
          :data="realtime.lists"
          :class-option="optionLefts"
          class="rolling"
        >
          <ul class="rolling-item-cs">
            <li v-for="(item, index) in realtime.lists" :key="index">
              <span class="title" v-text="index + 1"></span>
              <span class="address" v-text="item.createTime"></span>
              <span class="settled" v-text="item.orderNo"></span>
              <span class="settledNum" v-text="item.name"></span>
              <span class="settledNum">已服务</span>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
      <div class="noMessage" v-if="flag">暂无数据</div>
    </div>
  </div>
</template>

<script>
// 引入滚动插件
import vueSeamlessScroll from 'vue-seamless-scroll'
import { getRelatime } from '@/api/institution/right.js'
export default {
  // 定义数据
  data() {
    return {
      realtime: {
        total: 0, //累计服务人数
        amount: 0, //累计服务金额
        currCustomer: 0,
        currWorker: 0,
        lists: [],
      },
      flag: false,
      getMessage: null,
    }
  },
  // 注册组件
  components: {
    //滚动插件
    vueSeamlessScroll,
  },
  // 计算属性
  computed: {
    // 滚动数据
    optionLefts() {
      return {
        //控制滚动方向
        direction: 1,
        //控制多少条数开始滚动
        limitMoveNum: 3,
        //控制滚动速度，数值越大速度滚动越快
        step: 0.4,
      }
    },
    insNo() {
      return this.$route.query.insNo
    },
  },
  created() {
    this.getData()
    this.getMessage = setInterval(() => {
      this.getData()
    }, 10000)
  },
  methods: {
    getData() {
      getRelatime(this.insNo).then((res) => {
        if (res.code === 0) {
          this.realtime = res.data
          let data = this.realtime.amount
          if (data < 1000000) {
            var value = Math.round(parseFloat(data) * 100) / 100
            var s = value.toString().split('.')
            if (s.length == 1) {
              value = value.toString() + '.00'
              this.realtime.amount = value
            }
            if (s.length > 1) {
              if (s[1].length < 2) {
                this.realtime.amount = value.toString() + '0'
              }
              this.realtime.amount = value
            }
          } else {
            console.log(Math.round((data / 10000) * 100) / 100 + '万+')
            this.realtime.amount = Math.round(data / 10000) + '万+'
          }
          if (res.data.length == 0) {
            this.flag = true
          }
          if (this.realtime.lists.length === 0 || this.realtime.lists == null) {
            this.flag = true
          } else {
            this.flag = false
          }
        } else {
          this.flag = true
        }
      })
    },
    moneyDetail(data) {
      let k = 1000000
      if (data === 0) {
        return data
      }
      if (data < k) {
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.getMessage)
    this.getMessage = null
  },
}
</script>

<style scoped>
.yi-xiaotong {
  background: url('~@/assets/images/institution_images/top3bg.png') 0 0
    no-repeat;
  padding: 1.46rem 0 0.5rem 0;
  height: 11.99rem;
  width: 100%;
  box-sizing: border-box;
  background-size: 100% 100%;
}
.xiaotong {
  display: flex;
  margin-top: 0.1rem;
  justify-content: space-around;
}
.peopleAll {
  display: flex;
  justify-content: left;
  align-items: center;
  background: rgba(5, 13, 38, 0.31);
  color: #51b7ff;
  border-radius: 2px;
  border: 1px solid #213060;
  text-align: center;
  width: 48%;
  padding: 0.25rem 0.5rem;
  box-sizing: border-box;
}
.peopleAll i {
  font-size: 0.9rem;
  font-style: normal;
}
.spcol {
  color: #ff8529 !important;
}
.peopleAll > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.peopleAll > div > span {
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #00d5ff;
  margin-right: 0.5rem;
}
.peopleAll > div > span:last-child {
  font-size: 1.25rem;
  font-family: DINAlternate-Bold, DINAlternate;
}
.peopleAll > div > span:first-child {
  font-size: 0.58rem;
}
.peopleAll > div > ul {
  display: flex;
}
.peopleAll > div > ul > li {
  padding: 0.05rem 0.06rem;
  font-size: 1rem;
  background: #0079fe;
  border-radius: 4px;
  margin-right: 0.1rem;
}
.peopleAll > div > div {
  margin-left: 0.2rem;
}
.rolling {
  overflow: hidden;
  height: 4.8rem;
}
.rolling-item-cs > li {
  display: flex;
  justify-content: space-around;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 1.5rem;
}
.rolling-item-cs > li > span {
  display: inline-block;
  flex: 0.6;
}
.rolling-item-cs > li > span:first-child {
  display: inline-block;
  flex: 0.3;
}
.rolling-item-cs > li > span:last-child {
  flex: 0.3;
}

.order-display {
  margin-top: 0.2rem;
  background-size: 100% 100%;
  position: relative;
}
.noMessage {
  position: absolute;
  left: 9rem;
  top: 4.5rem;
  font-size: 1.2rem;
  color: #2d3769;
}
.order-cs {
  display: flex;
  justify-content: space-around;
  line-height: 1.25rem;
}
.order-cs > div {
  font-size: 0.58rem;
}
.order-cs > div:first-child {
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #69b1ff;
  width: 33%;
}
.order-cs > div:nth-child(2) {
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #8eb8e6;
}
.order-cs > div:nth-child(3) {
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #8eb8e6;
}

.display-cs {
  width: 98%;
  margin: 0 auto;
  box-sizing: border-box;
  background: rgba(5, 13, 38, 0.31);
  border: 1px solid #213060;
}
.rolling-item-cs {
  padding: 0 0.5rem;
}
.rolling-item-bor {
  border-bottom: 1px solid #213060;
}
</style>
